import { useMemo } from "react";
import { BillingTransactionApi, InvoiceApi } from "@practice/sdk";
import axios from "axios";
import { doc, UpdateData, updateDoc } from "firebase/firestore";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import {
  useGetFirestoreCollectionDataOnce,
  useGetFirestoreDocumentData,
} from "@contexts/data";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { db } from "@lib/firebase-config";
import { fixAppointment } from "@lib/utils/appointments";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useGetCalendarOwnerCallback } from "@components/availabilities/AvailabilityOwnerPreview";

type UseAppointmentType = (
  oid?: string,
  appointmentId?: string
) => {
  appointment?: AppointmentType;
  loading: boolean;
  isValidating: boolean;
  update: (data: UpdateData<AppointmentType>) => Promise<void>;
  cancelAppointment: (
    sendEmailNotifications?: boolean,
    origin?: "client" | "coach",
    updateOutcome?: boolean,
    outcomeId?: string,
    reason?: string
  ) => void;
  siblings?: AppointmentType[];
  loadingSiblings: boolean;
  samePackageAppointments?: AppointmentType[];
  loadingSamePackageAppointments: boolean;
};

const useAppointment: UseAppointmentType = (oid, appointmentId) => {
  const { data: appointment, error } = useGetFirestoreDocumentData(
    oid && appointmentId
      ? `users/${oid}/appointments/${appointmentId}`
      : undefined
  );

  const update = async (data: UpdateData<AppointmentType>) => {
    if (!oid || !appointmentId) return;
    const docRef = doc(db, `users/${oid}/appointments/${appointmentId}`);
    await updateDoc(docRef, data);
  };

  const { data: siblings, isLoading: loadingSiblings } =
    useGetFirestoreCollectionDataOnce<AppointmentType>({
      collectionPath:
        oid && appointment?.gcal?.event.recurringEventId
          ? `users/${oid}/appointments`
          : undefined,
      queryList: [
        [
          "gcal.event.recurringEventId",
          "==",
          appointment?.gcal?.event.recurringEventId,
        ],
      ],
    });

  const {
    data: samePackageAppointments,
    isLoading: loadingSamePackageAppointments,
  } = useGetFirestoreCollectionDataOnce<AppointmentType>({
    collectionPath:
      oid && appointment?.packageInstanceId
        ? `users/${oid}/appointments`
        : undefined,
    queryList: [["packageInstanceId", "==", appointment?.packageInstanceId]],
  });

  const cancelAppointment = (
    sendEmailNotifications?: boolean,
    origin?: "client" | "coach",
    updateOutcome?: boolean,
    outcomeId?: string,
    reason?: string
  ) => {
    return axios.post(`/api/users/${oid}/appointments/cancel`, {
      appointmentId,
      ...(sendEmailNotifications && { sendEmailNotifications }),
      ...(origin && { origin }),
      ...(updateOutcome && { updateOutcome }),
      ...(outcomeId && { outcomeId }),
      ...(reason && { reason }),
    });
  };

  const getCalendarOwner = useGetCalendarOwnerCallback();

  const fixedAppointment = useMemo(() => {
    return appointment && appointment.status !== "deleted"
      ? fixAppointment(appointment, oid, getCalendarOwner)
      : undefined;
  }, [getCalendarOwner, oid, appointment]);

  const fixedSiblings = useMemo(() => {
    return siblings?.map((appointment) => {
      return fixAppointment(appointment, oid, getCalendarOwner);
    });
  }, [getCalendarOwner, oid, siblings]);

  const fixedSamePackageAppointments = useMemo(() => {
    return samePackageAppointments?.map((appointment) => {
      return fixAppointment(appointment, oid, getCalendarOwner);
    });
  }, [getCalendarOwner, oid, samePackageAppointments]);

  return {
    appointment: fixedAppointment,
    loading: !appointment && !error,
    isValidating: !appointment && !error,
    update,
    cancelAppointment,
    siblings: fixedSiblings,
    loadingSiblings,
    samePackageAppointments: fixedSamePackageAppointments,
    loadingSamePackageAppointments,
  };
};

/**
 * Gets the reconciliation invoice related to the appointment
 * */
export const useReconciliationInvoiceAppointmentRelated = (
  appointmentId?: string
) => {
  const { oid } = useAuth();
  const invoiceApi = useSDKApi(InvoiceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-reconciliation-invoice-appointment-related"
  );

  const swrData = useSWR(
    appointmentId
      ? `organizations/${oid}/appointments/${appointmentId}/get-invoice-related`
      : null,
    async () => {
      if (!oid || !appointmentId) return;
      try {
        return invoiceApi.getAppointmentInvoiceRelated({
          xRequestId: generateRequestId(),
          organizationId: oid,
          appointmentId,
        });
      } catch (error) {
        const errorMessage = await catchErrorSDK(
          error,
          "Something went wrong while fetching the invoice related to the appointment"
        );
        return {
          data: null,
          error: errorMessage,
        };
      }
    },
    {
      // we don't need to revalidate the invoice in this case
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onErrorRetry: (error) => {
        // never retry to fetch if invoice is not found
        if (error.status === 404) return;
      },
    }
  );

  return {
    data: swrData.data,
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
  };
};

/**
 * Gets the reconciliation invoice related to the appointment
 * */
export const useAppointmentBillingTransaction = (appointmentId?: string) => {
  const { oid } = useAuth();
  const billingTransactionApi = useSDKApi(BillingTransactionApi);
  const generateRequestId = useRequestIdGenerator(
    "use-appointment-billing-transaction"
  );

  const swrData = useSWR(
    appointmentId
      ? `organizations/${oid}/appointments/${appointmentId}/get-billing-transaction`
      : null,
    async () => {
      if (!oid || !appointmentId) return;
      try {
        return billingTransactionApi.getAppointmentBillingTransaction({
          xRequestId: generateRequestId(),
          organizationId: oid,
          appointmentId,
        });
      } catch (error) {
        const errorMessage = await catchErrorSDK(
          error,
          "Something went wrong while fetching the billing transaction of an appointment"
        );
        return {
          data: null,
          error: errorMessage,
        };
      }
    },
    {
      onErrorRetry: (error) => {
        // never retry to fetch if invoice is not found
        if (error.status === 404) return;
      },
    }
  );

  return {
    data: swrData.data,
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
  };
};

export default useAppointment;
